<template>
  <div>
    <header class="grey-bg container-1560 header flex">
      <div>
        <b-link :to="{ name: 'home' }" class="logo">
          <img src="/logo.svg" alt="Mental Health Check" width="110" height="40" />
        </b-link>
      </div>

      <div class="flex">
          <div class="justfy-content-center align-items-center pr-1 d-none d-md-flex"></div>
          <div class="user-dropdown" @click="toggleDropdown" v-if="userData != undefined && userData.accessToken != undefined && userData.accessToken != ''">
              <a href="#" id="userDropbtn" class="button-base user-dropbtn d-flex align-items-center">
                <b-avatar :src="userData.avatar" size="40" />
                <div class="nav-username">
                  <div>{{ userData.name }}</div>
                  <div><small>{{ userData.username }}</small></div>
                </div>
              </a>
              <div id="userDropdownMenu" class="user-dropdown-menu" v-bind:class="{ active: isActive }">
                <b-link :to="{ name: 'home' }">
                  <feather-icon size="16" icon="UserIcon" class="mr-50" />
                  <span>Dashboard</span>
                </b-link>
                <b-link @click="logout">
                  <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
                  <span>Logout</span>
                </b-link>
              </div>
          </div>

          <div class="login" v-else>
            <b-link :to="{ name: 'login' }" class="btn btn-transparent-bg color-blue">
              <span class="">Log In</span>
            </b-link>
          </div>
      </div>
      
    </header>
    <main class="grey-bg main-background questionnaire-main">
        <div class="questionnaire-placeholder">  
          <router-view />
        </div>
    </main>

    <footer class="footer">
        <div class="container-1560">
            <div class="mt-1"><img src="/logo.svg" alt="Mental Health Check" width="110" height="40" /></div>
            <p>Copyright © 2021. MentalHealthCheck. All rights reserved.</p>
        </div>
    </footer>
  </div>
</template>

<script>
import store from "@/store/index";
import LayoutFull from "@core/layouts/layout-full/LayoutFull.vue";
import { BAvatar, BLink } from "bootstrap-vue";

export default {
  components: {
    LayoutFull,
    BAvatar,
    BLink,
  },
  data() {
    return {
      isActive: false,
    };
  },
  computed: {
    userData() {
      return store.state.app.userData;
    },
  },
  methods: {
    toggleDropdown() {
      this.isActive = !this.isActive;
    },
    logout() {      
      const userData = store.state.app.userData;
      const token = `${userData.token_type} ${userData.accessToken}`;

      const requestOptions = {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      };
      this.$http.get('/api/logout', requestOptions)
      .then(response => {        
        store.commit('app/removeUserData')
        localStorage.removeItem('userData');
      })
      .catch(error => {
        console.log(error)
        store.commit('app/removeUserData')
        localStorage.removeItem('userData');
      })
      store.commit('app/removeUserData')
      localStorage.removeItem('userData');
      this.$router.push("/login")
    },
  },
  created(){
    // Close dropdown on window click
    window.addEventListener('click', (e) => {
      if(e.target.id != 'userDropbtn') {
        this.isActive = false
      }
    })
  },
};
</script>

<style>
@import "../../assets/css/style.css";

#app {
  background-color: #f1f1f1 !important;
}
</style>
